@import "../../../styles/_var.scss";
.industry {
}
.industryBody {
    margin-bottom: 180px;
}
.industryTitle {
    @media (max-width: $md3+px) {
        text-align: center;
    }
}

.industrySubtitle {
    color: #4d4d4d;
    max-width: 790px;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.4;
    margin-bottom: 60px;
    @media (max-width: $md3+px) {
        text-align: center;
        margin: 0 auto 40px auto;
    }
    @media (max-width: $md5+px) {
        font-size: 22px;
    }
    @media (max-width: $md6+px) {
        font-size: 20px;
    }
}

.industryRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
        justify-content: center;
    }
}
.industryRowColumn {
    flex: 0 1 513px;
    height: 629px;
    background-color: #184039;
    box-shadow: 0px 0px 30px rgba(0, 95, 203, 0.1);
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    // cursor: pointer;
    transition: all 0.5s linear;
    @media (max-width: $md1+px) {
        flex: 0 1 413px;
    }
    @media (max-width: $md2+px) {
        flex: 0 1 395px;
    }
    @media (max-width: $md3+px) {
        flex: 0 1 513px;
        margin-bottom: 30px;
    }
    @media (max-width: 600px) {
        flex: 0 1 413px;
    }
    @media (max-width: 440px) {
        flex: 0 1 365px;
    }
    &:nth-child(1) {
        .industryRowColumnItem {
            position: relative;
            &:after {
                content: "";
                display: "inline-block";
                left: 0;
                top: 0;
                position: absolute;
                width: 100%;
                transition: all 0.5s linear;
                height: 100%;
                background: url("../../../assets/img/ConditionsPage/3/1.png") center 30px no-repeat;
            }
        }

        &:hover {
            background-color: #e0ffa3;
            .industryRowColumnItemBotRowLeft {
                font-weight: 600;
                font-size: 20px;
                color: #444d31;
            }
            .industryRowColumnItemBotRowRight {
                font-weight: 400;
                font-size: 20px;
                color: #444d31;
            }
            .industryRowColumnItemTitle {
                color: #184039;
            }
            .industryRowColumnItem {
                padding-top: 80px;
            }
            .industryRowColumnItemBot {
                bottom: 30px;
            }
            .industryRowColumnItem {
                &:after {
                    opacity: 0.2;
                    transform: scale(1.4);
                    left: 150px;
                    top: -25px;
                }
            }
        }
    }
    &:nth-child(2) {
        .industryRowColumnItem {
            position: relative;
            &:after {
                content: "";
                display: "inline-block";
                left: 0;
                top: 0;
                position: absolute;
                width: 100%;
                transition: all 0.5s linear;
                height: 100%;
                background: url("../../../assets/img/ConditionsPage/3/2.png") center 30px no-repeat;
            }
        }

        .industryRowColumnItem1 {
            background: url("../../../assets/img/ConditionsPage/3/22.png") 0 0px no-repeat;
        }
        &:hover {
            background-color: #e0ffa3;
            .industryRowColumnItemBotRowLeft {
                font-weight: 600;
                font-size: 20px;
                color: #444d31;
            }
            .industryRowColumnItemBotRowRight {
                font-weight: 400;
                font-size: 20px;
                color: #444d31;
            }
            .industryRowColumnItemTitle {
                color: #184039;
            }
            .industryRowColumnItem {
                padding-top: 80px;
            }
            .industryRowColumnItemBot {
                bottom: 30px;
            }
            .industryRowColumnItem {
                &:after {
                    opacity: 0.2;
                    transform: scale(1.4);
                    left: -185px;
                    top: -75px;
                }
            }
        }
    }
    &:nth-child(3) {
        .industryRowColumnItem {
            position: relative;
            &:after {
                content: "";
                display: "inline-block";
                left: 0;
                top: 0;
                position: absolute;
                width: 100%;
                transition: all 0.5s linear;
                height: 100%;
                background: url("../../../assets/img/ConditionsPage/3/3.png") center 30px no-repeat;
            }
        }
        &:hover {
            background-color: #e0ffa3;
            .industryRowColumnItemBotRowLeft {
                font-weight: 600;
                font-size: 20px;
                color: #444d31;
            }
            .industryRowColumnItemBotRowRight {
                font-weight: 400;
                font-size: 20px;
                color: #444d31;
            }
            .industryRowColumnItemTitle {
                color: #184039;
            }
            .industryRowColumnItem {
                padding-top: 80px;
            }
            .industryRowColumnItemBot {
                bottom: 30px;
            }
            .industryRowColumnItem {
                &:after {
                    opacity: 0.2;
                    transform: scale(1.4);
                    left: 220px;
                    top: -130px;
                }
            }
        }
    }
}
.industryRowColumnItem {
    padding-top: 30px;
    height: 100%;
    transition: all 0.5s linear;
    overflow: hidden;
}
.industryRowColumnItemTitle {
    font-family: "Cabin", sans-serif;
    font-weight: 600;
    font-size: 40px;
    line-height: 1;
    color: #ffffff;
    text-align: center;
}
.industryRowColumnItemBot {
    position: absolute;
    bottom: -60px;
    left: 0;
    transition: all 0.5s linear;
    right: 0;
}
.industryRowColumnItemBotRow {
    width: 274px;
    margin: 0 auto 10px auto;
    display: flex;
    justify-content: space-between;
}
.industryRowColumnItemBotRowLeft {
    color: #b9c5c3;
    font-weight: 600;
    font-size: 20px;
}
.industryRowColumnItemBotRowRight {
    font-weight: 400;
    font-size: 20px;
    color: #b9c5c3;
}

.industryRowColumnItem1 {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    transition: all 0.3s linear;
    padding-top: 80px;
    height: 100%;
    width: 100%;
}
.industryRowColumnItemTitle1 {
    font-family: "Cabin", sans-serif;
    font-weight: 600;
    font-size: 40px;
    line-height: 1;
    color: #184039;
    text-align: center;
}
.industryRowColumnItemBot1 {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
}
.industryRowColumnItemBotRow1 {
    width: 274px;
    margin: 0 auto 10px auto;
    display: flex;
    justify-content: space-between;
}
.industryRowColumnItemBotRowLeft1 {
    font-weight: 600;
    font-size: 20px;
    color: #444d31;
}
.industryRowColumnItemBotRowRight1 {
    font-weight: 400;
    font-size: 20px;
    color: #444d31;
}
.industryRowColumnItem1Start {
    // width: 189px;
    height: 54px;
    line-height: 54px;
    // border-radius: 30px;
    // font-size: 20px;
    text-align: center;
    margin: 40px auto 0 auto;
    z-index: 3;
    position: relative;
    cursor: pointer;
    a {
        background: #184039;
        color: #ffffff;
        &:hover {
            color: #ffffff;
            background: #12302b;
        }
    }
}
