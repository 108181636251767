@import '../../../styles/var';

.shortOverview {
    position: relative;
    margin-top: 180px;
    @media(min-width: $md3+px) {
        &:hover {
            .coin_01 {
                right: -120px!important;
                transform: rotate(-20deg);
                scale: 0.7;
                @media(max-width: $md2+px) {
                    right: -50px!important;
                }
            }
            .coin_02 {
                top: -80px!important;
                transform: rotate(-20deg);
                @media(max-width: $md2+px) {
                    top: -20px!important;
                }
            }
        }
    }
    @media(max-width: $md6+px) {
        margin-top: 150px;
    }
    .header {
        width: 680px;
        @media(max-width: $md5+px) {
            width: 100%;
        }
    }
    .content {
        position: relative;
        display: flex;
        @media(max-width: $md3+px) {
            display: block;
        }
        .text {
            width: 790px;
            @media(max-width: $md1+px) {
                width: 680px;
            }
            @media(max-width: $md2+px) {
                width: 650px;
            }
            @media(max-width: $md3+px) {
                width: 100%;
            }
            .par {
                color: rgba(0, 0, 0, 0.7);
                margin-top: 40px;
                @media(max-width: $md6+px) {
                    margin-top: 20px;
                }
            }
            .par2 {
                margin-top: 20px;
                color: rgba(0, 0, 0, 0.7);
            }
        }
        .image {
            height: 100%;
            margin-top: auto;
            margin-left: auto;
            display: flex;
            position: absolute;
            right: 150px;
            bottom: 0;
            @media(max-width: $md1+px) {
                right: 50px;
            }
            @media(max-width: $md2+px) {
                right: 100px;
            }
            @media(max-width: $md3+px) {
                margin-top: 40px;
                position: relative;
                right: unset;
                bottom: unset
            }
            img {
                transition: all 1s;
            }
            .girl {
                width: 522px;
                // position: relative;
                z-index: 3;
                position: absolute;
                bottom: 0;
                right: 0;
                @media(max-width: $md2+px) {
                    width: 350px;
                }
                @media(max-width: $md3+px) {
                    width: 300px;
                    margin: 0 auto 0 auto;
                    position: relative;
                }
                @media(max-width: $md6+px) {
                    width: 200px;
                }
            }
            .coin_01 {
                position: absolute;
                z-index: 1;
                top: -300px;
                right: -200px;
                scale: 0.7;
                @media(max-width: $md2+px) {
                    width: 350px;
                    top: -200px;
                    right: -100px;
                }
                @media(max-width: $md3+px) {
                    width: 200px;
                    top: -70px;
                    right: 250px;
                    scale: 1;
                }
                @media(max-width: $md4+px) {
                    width: 200px;
                    top: -70px;
                    right: 180px;
                }
                @media(max-width: $md6+px) {
                    width: 150px;
                    right: 30px;
                }
            }
            .coin_02 {
                position: absolute;
                z-index: 2;
                top: 0;
                right: -300px;
                scale: 0.8;
                @media(max-width: $md2+px) {
                    width: 400px;
                    right: -200px;
                }
                @media(max-width: $md3+px) {
                    width: 250px;
                    top: 50px;
                    right: 200px;
                }
                @media(max-width: $md4+px) {
                    width: 250px;
                    top: 50px;
                    right: 100px;
                }
                @media(max-width: $md6+px) {
                    width: 180px;
                    right: 0;
                }
            }
        }
    }
}