@import '../../../styles/var';

.tableBlock {
    .bgGrey {
        background-color: #FCFCFC;
    }
    margin-top: 100px;
    @media(max-width: $md6+px) {
        margin-top: 70px;
    }
    table {
        width: 100%;
        border-spacing: 0;
        tr {
            font-weight: 400;
            font-size: 24px;
            line-height: 33px;
            transition: all .3s;
            border-spacing: 0;
            @media(max-width: $md3+px) {
                font-size: 18px;
                line-height: normal;
            }
            @media(max-width: $md6+px) {
                font-size: 14px;
            }
            &:hover {
                @media(min-width: $md3+px) {
                    scale: 1.02;
                }
            }
            &:nth-child(1) {
                &:hover {
                    scale: 1;
                }
                td {
                    padding-bottom: 0;
                    padding-top: 0;
                    font-weight: 700;
                }
            }
            td {
                padding-top: 14px;
                padding-bottom: 14px;
                @media(max-width: $md6+px) {
                    padding-top: 8px;
                    padding-bottom: 8px;
                }
                &:nth-child(1) {
                    text-align: right;
                    padding-left: 100px;
                    padding-right: 25px;
                    width: 30px;
                    @media(max-width: $md3+px) {
                        padding-left: 50px;
                    }
                    @media(max-width: $md4+px) {
                        padding-left: 20px;
                    }
                    @media(max-width: $md6+px) {
                        img {
                            width: 20px;
                        }
                        width: 20px;
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                }
                &:nth-child(3) {
                    text-align: right;
                    padding-right: 110px;
                    @media(max-width: $md3+px) {
                        padding-right: 50px;
                    }
                    @media(max-width: $md4+px) {
                        padding-right: 0;
                    }
                }
                &:nth-child(4) {
                    padding-left: 110px;
                    @media(max-width: $md3+px) {
                        padding-left: 50px;
                        padding-right: 20px;
                    }
                    @media(max-width: $md6+px) {
                        padding-left: none;
                    }
                }
                &:nth-child(5) {
                    text-align: right;
                    padding-right: 100px;
                    @media(max-width: $md1+px) {
                        width: 400px;
                    }
                    @media(max-width: $md3+px) {
                        padding-right: 50px;
                    }
                    @media(max-width: $md4+px) {
                        padding-right: 20px;
                        width: 320px;
                    }
                    @media(max-width: $md5+px) {
                        display: none;
                        width: unset
                    }
                }
            }
        }
    }
    .btn {
        @media(min-width: $md5+px) {
            display: none;
        }
        @media(max-width: $md5+px) {
            margin-top: 30px;
        }
    }
}