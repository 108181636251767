@import '../../../styles/var';

.outPlatform {
    margin-top: 180px;
    position: relative;
    @media(max-width: $md6+px) {
        margin-top: 150px;
    }
    .light {
        position: absolute;
        background-image: url('../../../assets/img/MainPage/platform_light.png');
        width: 590px;
        height: 590px;
        background-size: cover;
        right: 0;
        top: 50px;
        z-index: 1;
    }
    .header {
        position: relative;
        z-index: 2;
        color: white;
        width: 828px;
        margin: 0 auto;
        text-align: center;
        @media(max-width: $md4+px) {
            text-align: left;
            width: 100%;
        }
    }
    .text {
        position: relative;
        z-index: 2;
        margin: 40px auto 60px auto;
        width: 928px;
        text-align: center;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 44px;
        color: rgba(255, 255, 255, 0.7);
        @media(max-width: $md2+px) {
            font-size: 24px;
            line-height: normal;
            width: 800px;
        }
        @media(max-width: $md4+px) {
            text-align: left;
            width: 100%;
        }
        @media(max-width: $md6+px) {
            font-size: 16px;
            margin: 20px 0 30px 0;
        }
    }
    .cards {
        position: relative;
        z-index: 2;
        display: flex;
        justify-content: center;
        gap: 50px;
        @media(max-width: $md4+px) {
            display: grid;
            grid-template: repeat(2,1fr)/repeat(2,0fr);
            gap: 40px;
        }
        @media(max-width: $md5+px) {
            display: block;
        }
        .card {
            background: #184039;
            border-radius: 20px;
            width: 375px;
            padding: 30px 10px;
            text-align: center;
            transition: all 0.3s;
            cursor: pointer;
            @media(max-width: $md1+px) {
                width: 300px;
                padding: 25px 10px;
            }
            @media(max-width: $md2+px) {
                width: 280px;
            }
            @media(max-width: $md3+px) {
                width: 230px;
                padding: 20px 5px;
            }
            @media(max-width: $md4+px) {
                width: 300px;
            }
            @media(max-width: $md5+px) {
                width: 100%;
                margin-bottom: 20px;
            }
            &:hover {
                @media(min-width: $md5+px) {
                    margin-top: -20px;
                    margin-bottom: 20px;
                }
            }
            &Header {
                font-family: 'Cabin';
                font-style: normal;
                font-weight: 600;
                font-size: 40px;
                line-height: 49px;
                color: #E0FFA3;
                @media(max-width: $md1+px) {
                    font-size: 30px;
                    line-height: normal;
                }
            }
            &Text {
                font-weight: 400;
                font-size: 24px;
                line-height: 33px;
                color: rgba(255, 255, 255, 0.7);
                margin-top: 15px;
                @media(max-width: $md1+px) {
                    font-size: 20px;
                    line-height: normal;
                    margin-top: 10px;
                }
            }
        }
    }
}