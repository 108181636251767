@import "../../../styles/_var.scss";
.short {
}
.shortBody {
    padding: 130px 0 170px 0;
    background: linear-gradient(279.92deg, #0d2421 36.61%, #204b3f 68.05%, #2b5840 90.51%);
    position: relative;
    &:after {
        content: "";
        display: "inline-block";
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background: url("../../../assets/img/TradingSpacePage/4/1.png") right 0 no-repeat;
        @media (max-width: $md1+px) {
            background: url("../../../assets/img/TradingSpacePage/4/1.png") right center / 700px auto no-repeat;
        }
        @media (max-width: $md2+px) {
            background: url("../../../assets/img/TradingSpacePage/4/1.png") right center / 600px auto no-repeat;
        }
        @media (max-width: $md3+px) {
            background: url("../../../assets/img/TradingSpacePage/4/1.png") center bottom / 800px auto no-repeat;
            left: -60px;
        }

        @media (max-width: $md5+px) {
            background: url("../../../assets/img/TradingSpacePage/4/1.png") center bottom / 500px auto no-repeat;
            left: -20px;
        }
        @media (max-width: $md6+px) {
            background: url("../../../assets/img/TradingSpacePage/4/1.png") center bottom / 350px auto no-repeat;
        }
    }
    @media (max-width: $md3+px) {
        padding: 130px 0 570px 0;
    }
    @media (max-width: $md5+px) {
        padding: 130px 0 400px 0;
    }
    @media (max-width: $md6+px) {
        padding: 130px 0 320px 0;
    }
}
.shortTitle {
    color: #fff !important;
    max-width: 652px;
    position: relative;
    z-index: 3;
    @media (max-width: $md3+px) {
        text-align: center;
        margin: 0 auto 20px auto;
    }
}

.shortSubtitle {
    color: #bac6c3;
    font-size: 24px;
    line-height: 1.4;
    position: relative;
    z-index: 3;
    max-width: 790px;
    @media (max-width: $md3+px) {
        text-align: center;
        margin: 0 auto 0px auto;
    }
    @media (max-width: $md5+px) {
        font-size: 22px;
    }
    @media (max-width: $md6+px) {
        font-family: 20px;
    }
}
