@import "../../../styles/_var.scss";
.spreads {
}
.spreadsBody {
}
.spreadsWrapper {
    position: relative;
    padding: 100px 0 180px 0;
    @media (max-width: $md3+px) {
        padding: 100px 0 680px 0;
    }
}
.spreadsTitle {
    position: relative;
    z-index: 3;
    @media (max-width: $md4+px) {
        text-align: center;
    }
}
.spreadsSubtitle {
    font-family: "Cabin", sans-serif;
    font-weight: 600;
    font-size: 40px;
    line-height: 1.2;
    color: #000000;
    margin-bottom: 40px;
    position: relative;
    z-index: 3;
    @media (max-width: $md4+px) {
        text-align: center;
        font-size: 35px;
    }
    @media (max-width: $md5+px) {
        font-size: 30px;
    }
    @media (max-width: $md6+px) {
        font-size: 25px;
    }
}
.spreadsText {
    color: #4d4d4d;
    font-size: 24px;
    line-height: 1.4;
    max-width: 790px;
    margin-bottom: 40px;
    position: relative;
    z-index: 3;
    @media (max-width: $md4+px) {
        text-align: center;
    }
    @media (max-width: $md5+px) {
        font-size: 22px;
    }
    @media (max-width: $md6+px) {
        font-size: 20px;
    }
}

.spreadsBtn {
    // width: 189px;
    // height: 54px;
    // background: #e0ffa3;
    // border-radius: 30px;
    // text-align: center;
    // line-height: 54px;
    // font-size: 20px;
    // cursor: pointer;
    // color: #000000;
    position: relative;
    z-index: 3;
    @media (max-width: $md3+px) {
        margin: 0 auto 0 auto;
    }
    // transition: all 0.3s linear;
    // &:hover {
    //     background-color: #ebffc3;
    // }
}
.spreadsWrapperPlanet {
    position: absolute;
    right: -150px;
    top: 10px;
    @media (max-width: $md3+px) {
        top: unset;
        bottom: 0;
        right: 0;
    }
    @media (max-width: $md4+px) {
        right: -120px;
    }
    @media (max-width: 630px) {
        right: 0px;
        left: -190px;
    }
}
.spreadsWrapperZipper1 {
    position: absolute;
    right: 375px;
    top: 165px;
    width: 352px;
    @media (max-width: $md3+px) {
        top: unset;
        bottom: 165px;
        right: 475px;
    }
    @media (max-width: 630px) {
        right: 475px;
        left: 0;
        transform: scale(0.6);
        bottom: 215px;
    }
    img {
        width: 100%;
    }
    transition: all 0.3s linear;
    &.active {
        transform: translate(30px, -30px);
    }
    @media (max-width: $md6+px) {
        left: -60px;
    }
    @media (max-width: 425px) {
        left: -120px;
    }
}
.spreadsWrapperZipper2 {
    position: absolute;
    right: 150px;
    top: 165px;
    width: 464px;
    img {
        width: 100%;
    }
    transition: all 0.3s linear;
    &.active {
        transform: translate(50px, -50px);
    }
    @media (max-width: $md3+px) {
        top: unset;
        bottom: 120px;
        right: 275px;
    }
    @media (max-width: 630px) {
        right: 275px;
        left: 0;
        transform: scale(0.6);
        bottom: 170px;
    }
    @media (max-width: $md6+px) {
        left: -50px;
    }
    @media (max-width: 425px) {
        left: -110px;
    }
}
.spreadsWrapperZipper3 {
    position: absolute;
    right: -0px;
    top: 110px;
    width: 616px;
    transition: all 0.3s linear;
    img {
        width: 100%;
    }
    &.active {
        transform: translate(15px, -15px);
    }
    @media (max-width: $md3+px) {
        top: unset;
        bottom: 0;
        bottom: 90px;
        right: 135px;
    }
    @media (max-width: 630px) {
        right: 0;
        left: -30px;
        bottom: 140px;
        transform: scale(0.6);
    }
    @media (max-width: $md6+px) {
        left: -80px;
    }
    @media (max-width: 425px) {
        left: -140px;
    }
}
.spreadsWrapperZipper4 {
    position: absolute;
    right: 75px;
    top: 295px;
    width: 352px;
    transition: all 0.3s linear;
    img {
        width: 100%;
    }
    &.active {
        transform: translate(30px, -30px);
    }
    @media (max-width: $md3+px) {
        top: unset;

        bottom: 70px;
        right: 215px;
    }
    @media (max-width: 630px) {
        right: 215px;
        left: 0;
        transform: scale(0.6);
        bottom: 150px;
        left: 130px;
    }
    @media (max-width: $md6+px) {
        left: 80px;
    }
    @media (max-width: 425px) {
        left: 30px;
    }
}
