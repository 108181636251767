// @import "parts/titles.module.scss";
// @import "parts/buttons.module.scss";
// @import "parts/headerSlider.module.scss";
// @import "parts/collectionsColumn.module.scss";
// @import "parts/baner.module.scss";
// @import "parts/otherPageColumn.module.scss";

@import "./_var.scss";
body,
html {
    background-color: white;
    color: black;
    // overflow-x: hidden;
}

.ovf-hidden {
    overflow: hidden;
}

.preloader {
    position: fixed;
    z-index: 999999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(33.07deg, #0D2421 39.43%, #204B3F 82.35%, #2B5840 113%);
    opacity: 1;
    transition: all 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    &.hidePreloader {
      opacity: 0;
      pointer-events: none;
    }
}

.loader {
    width: 48px;
    height: 48px;
    border: 5px solid;
    border-color: #E0FFA3 transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 

.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

.Dropdown-control {
    background-color: transparent;
    border: none !important;
    color: #21211d;
    text-transform: uppercase;
    padding-right: 30px !important;
    font-size: 16px;
}

.header-default {
    font-family: "Cabin", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 64px;
    line-height: normal;
    color: #0d2421;
    @media (max-width: $md2+px) {
        font-size: 48px;
    }
    @media (max-width: $md6+px) {
        font-size: 36px;
    }
    span {
        color: #e0ffa3;
    }
}

.text-default {
    font-weight: 400;
    font-size: 24px;
    line-height: normal;
    @media (max-width: $md2+px) {
        font-size: 16px;
        line-height: normal;
    }
    @media (max-width: $md6+px) {
        font-size: 14px;
        line-height: normal;
    }
}

.btn-default {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #000000;
    padding: 14px 30px;
    background-color: #e0ffa3;
    border-radius: 30px;
    cursor: pointer;
    display: inline-flex;
    &:hover {
        background-color: #ebffc4;
    }
}

.title {
    font-family: "Cabin", sans-serif;
    font-weight: 600;
    font-size: 64px;
    line-height: 1.3;
    color: #0d2421;
    margin-bottom: 40px;
    @media (max-width: $md4+px) {
        font-size: 50px;
    }
    @media (max-width: $md5+px) {
        font-size: 40px;
    }
    @media (max-width: $md6+px) {
        font-size: 30px;
    }
}
