@import '../../../styles/var';

.benefits {
    margin-top: 180px;
    padding-bottom: 100px;
    @media(max-width: $md6+px) {
        margin-top: 150px;
        padding-bottom: 30px;
    }
    .header {
        color: white;
    }
    .cards {
        margin-top: 60px;
        display: grid;
        grid-template: repeat(2,1fr)/repeat(2,1fr);
        gap: 40px;
        @media(max-width: $md4+px) {
            display: block;
            margin-top: 40px;
        }
        .card {
            cursor: pointer;
            &:nth-child(1) {
                background-image: url('../../../assets/img/MainPage/benefits_01.png');
                background-repeat: no-repeat;
                background-position: center top;
            }
            &:nth-child(2) {
                background-image: url('../../../assets/img/MainPage/benefits_02.png');
                background-repeat: no-repeat;
                background-position: center top;
            }
            &:nth-child(3) {
                background-image: url('../../../assets/img/MainPage/benefits_03.png');
                background-repeat: no-repeat;
                background-position: center top;
            }
            &:nth-child(4) {
                background-image: url('../../../assets/img/MainPage/benefits_04.png');
                background-repeat: no-repeat;
                background-position: center top;
            }
            position: relative;
            width: 790px;
            height: 530px;
            background-color: #184039;
            border-radius: 20px;
            transition: all .5s;
            @media(max-width: $md1+px) {
                width: 630px;
                height: 450px;
            }
            @media(max-width: $md2+px) {
                width: 580px;
                height: 400px;
            }
            @media(max-width: $md3+px) {
                width: 475px;
                height: 400px;
            }
            @media(max-width: $md4+px) {
                width: 100%;
                height: 300px;
                margin-bottom: 30px;
            }
            @media(max-width: $md5+px) {
                height: 400px;
            }
            @media(max-width: $md6+px) {
                height: 300px;
            }
            @media(max-width: 400px) {
                height: 320px;
            }
            @media(max-width: 380px) {
                height: 350px;
            }
            &:hover .content {
                height: 77%;
                @media(max-width: $md6+px) {
                    height: 90%;
                }
                .cardHeader {
                    font-size: 36px;
                    line-height: 44px;
                    margin-bottom: 0px;
                    @media(max-width: $md1+px) {
                        font-size: 25px;
                        line-height: normal;
                    }
                    @media(max-width: $md3+px) {
                        font-size: 22px;
                    }
                    @media(max-width: $md6+px) {
                        font-size: 19px;
                    }
                    @media(max-width: 380px) {
                        font-size: 18px;
                    }
                }
            }
            &:hover .cardWr {
                background-color: rgba(24, 64, 57, 0.8);
            }
            &Wr {
                width: 100%;
                height: 100%;
                background-color: transparent;
                transition: all .5s;
                border-radius: 20px;
            }
            .content {
                margin: 40px;
                transition: all .5s;
                position: absolute;
                bottom: 0;
                height: 100px;
                overflow: hidden;
                @media(max-width: $md1+px) {
                    height: 80px;
                }
                @media(max-width: $md2+px) {
                    height: 70px;
                }
                @media(max-width: $md3+px) {
                    height: 55px;
                }
                @media(max-width: $md6+px) {
                    height: 45px;
                    margin: 20px;
                }
                .cardHeader {
                    font-family: 'Cabin', sans-serif;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 39px;
                    line-height: 49px;
                    color: #FFFFFF;
                    transition: all .5s;
                    margin-bottom: 100px;
                    @media(max-width: $md1+px) {
                        font-size: 30px;
                        line-height: normal;
                    }
                    @media(max-width: $md2+px) {
                        font-size: 28px;
                    }
                    @media(max-width: $md3+px) {
                        font-size: 22px;
                    }
                    @media(max-width: $md6+px) {
                        font-size: 19px;
                    }
                    @media(max-width: 380px) {
                        font-size: 18px;
                    }
                }
                .cardText {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: normal;
                    color: rgba(255, 255, 255, 0.7);
                    margin: 20px 0 60px 0;
                    @media(max-width: $md1+px) {
                        font-size: 18px;
                        line-height: normal;
                        margin-bottom: 35px;
                    }
                    @media(max-width: $md2+px) {
                        font-size: 16px;
                        margin: 20px 0 30px 0;
                    }
                    @media(max-width: $md3+px) {
                        font-size: 14px;
                    }
                    @media(max-width: $md6+px) {
                        font-size: 12px;
                    }
                }
                .cardBtn {
                    position: relative;
                    margin-top: -20px;
                    @media(max-width: $md6+px) {
                        font-size: 16px;
                        padding: 10px 20px;
                    }
                }
            }
        }
    }
}