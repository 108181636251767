@import '../../../styles/var';

.footer {
    background-color: #184039;
    overflow-x: hidden;
    margin-top: 180px;
    @media(max-width: $md6+px) {
        margin-top: 150px;
    }
    .container {
        @media(max-width: $md6+px) {
            padding: 0!important;
        }
    }
    .contactUs {
        position: relative;
        display: flex;
        padding-top: 180px;
        padding-bottom: 180px; /////////////////////////////////////// !!!!!!!!!
        @media(max-width: $md4+px) {
            display: block;
        }
        @media(max-width: $md5+px) {
            padding-top: 100px;
        }
        .header {
            position: relative;
            z-index: 2;
            color: white;
            width: 870px;
            @media(max-width: $md4+px) {
                width: 100%;
            }
        }
        .form {
            padding-left: 50px;
            @media(max-width: $md4+px) {
                padding-left: 0;
                margin: 50px auto 0 auto;
                width: 465px;
            }
            @media(max-width: $md5+px) {
                width: 100%;
            }
            input {
                position: relative;
                z-index: 3;
                display: block;
                font-family: 'Open Sans', sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 44px;
                color: white;
                padding: 6px 25px 7px 25px;
                background: #0D2421;
                border-radius: 30px;
                width: 464px;
                margin-bottom: 15px;
                @media(max-width: $md5+px) {
                    width: 100%;
                }
                &::placeholder {
                    color: #515151;
                }
            }
            .btn {
                position: relative;
                z-index: 3;
                margin-top: 40px;
                img {
                    margin-left: 15px;
                    padding-top: 1px;
                }
                @media(min-width: $md4+px) {
                    &:hover {
                        & ~ .coin_01 {
                            transform: rotate(-10deg);
                            left: -260px;
                            top: -80px;
                        }
                        & ~ .coin_02 {
                            transform: rotate(10deg);
                            left: 50px;
                            bottom: -80px;
                        }
                        & ~ .coin_03 {
                            transform: rotate(20deg);
                            scale: 0.8;
                            bottom: -250px;
                            right: -150px;
                        }
                    }
                }
            }
            .coin_01, .coin_02, .coin_03 {
                position: absolute;
                z-index: 1;
                transition: all 1s;
            }
            .coin_01 {
                left: -150px;
                top: -20px;
                @media(max-width: $md5+px) {
                    width: 300px;
                    left: auto;
                    top: -50px;
                    right: -50px;
                }
            }
            .coin_02 {
                bottom: -30px;
                left: 200px;
                @media(max-width: $md5+px) {
                    bottom: 0;
                    left: 0;
                    width: 150px;
                }
            }
            .coin_03 {
                right: -80px;
                bottom: -150px;
                @media(max-width: $md5+px) {
                    bottom: 0;
                    right: 0;
                    width: 300px;
                }
            }
        }
    }
    .under {
        padding: 60px 75px 75px 75px;
        color: rgba(255, 255, 255, 0.7);
        background: linear-gradient(0deg, #0D2421, #0D2421), #FFFFFF;
        display: grid;
        grid-template: repeat(2,0fr)/repeat(3,1fr);
        gap: 40px 40px;
        grid-template-columns: 400px 1fr 1fr;
        @media(max-width: $md1+px) {
            grid-template-columns: 1fr 1fr 1fr;
        }
        @media(max-width: $md3+px) {
            grid-template: repeat(3,0fr)/repeat(2,1fr);
            padding: 40px;
        }
        @media(max-width: $md5+px) {
            grid-template: repeat(6,0fr)/repeat(1,1fr);
        }
        @media(max-width: $md6+px) {
            padding: 8px;
        }
        a {
            color: rgba(255, 255, 255, 0.7);
            display: block;
        }
        .grid_01 {
            font-weight: 400;
            font-size: 16px;
            line-height: normal;
            .logo {
                img {
                    width: 114px;
                }
            }
            .num {
                margin-top: 35px;
            }
            .mail {
                margin-top: 13px;
            }
        }
        .grid_02, .grid_03, .grid_04 {
            .item {
                display: block;
                cursor: pointer;
                font-weight: 400;
                font-size: 16px;
                line-height: normal;
                margin-bottom: 13px;
                // color: white
                color: rgba(255, 255, 255, 0.7);
                a {
                    color: rgba(255, 255, 255, 0.7);
                }
            }
        }
        .grid_05, .grid_06 {
            .header {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: #FFFFFF;
            }
            .text {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                margin-top: 6px;
            }
        }
    }
}