@import '../../../styles/var';

.banner {
    padding-top: 123px;
    padding: 120px 0 290px 0;
    position: relative;
    @media(max-width: $md6+px) {
        padding: 70px 0 200px 0;
    }
    .content {
        position: relative;
        z-index: 2;
        .header {
            color: white;
            width: 968px;
            @media(max-width: $md2+px) {
                width: 750px;
            }
            @media(max-width: $md5+px) {
                width: 100%;
            }
            &Ru {
                width: 1000px;
                @media(max-width: $md2+px) {
                    width: 750px;
                }
                @media(max-width: $md5+px) {
                    width: 100%;
                }
            }
        }
        .text {
            font-weight: 400;
            font-size: 24px;
            line-height: 33px;
            color: rgba(255, 255, 255, 0.7);
            width: 748px;
            margin: 40px 0 60px 0;
            @media(max-width: $md2+px) {
                font-size: 20px;
                line-height: normal;
                width: 600px;
                margin: 30px 0 50px 0;
            }
            @media(max-width: $md5+px) {
                width: 100%;
            }
            @media(max-width: $md6+px) {
                font-size: 14px;
                margin: 30px 0;
            }
        }
        .btn {
            @media(min-width: $md3+px) {
                &:hover {
                    & ~ img {
                        scale: 1.05;
                        @media(max-width: $md2+px) {
                            scale: 0.85;
                        }
                    }
                    & ~ .apple {
                        bottom: -300px;
                        left: 23%;
                    }
                    & ~ .tesla {
                        bottom: -230px;
                        left: 51%;
                    }
                    & ~ .btc {
                        bottom: -20px;
                        left: 70%;
                    }
                    & ~ .eth {
                        bottom: -300px;
                        left: 85%;
                    }
                }
            }
        }
        .desc {
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #E0FFA3;
            margin-top: 5px;
        }
        img {
            position: absolute;
            z-index: 1;
            transition: all 1s;
            @media(max-width: $md2+px) {
                scale: 0.8;
            }
            @media(max-width: $md4+px) {
                scale: 0.6;
            }
            @media(max-width: $md6+px) {
                scale: 0.4;
            }
        }
        .apple {
            left: 25%;
            bottom: -250px;
            @media(max-width: $md2+px) {
                left: 10%;
            }
            @media(max-width: $md6+px) {
                left: -20px;
                bottom: -230px;
            }
        }
        .tesla {
            left: 50%;
            bottom: -200px;
            @media(max-width: $md2+px) {
                left: 35%;
            }
            @media(max-width: $md6+px) {
                left: 30%;
                bottom: -170px;
            }
        }
        .btc {
            left: 65%;
            bottom: 0px;
            @media(max-width: $md2+px) {
                left: 55%;
                bottom: -100px;
            }
            @media(max-width: $md6+px) {
                left: auto;
                right: -50px;
                bottom: -50px;
            }
        }
        .eth {
            left: 80%;
            bottom: -270px;
            @media(max-width: $md2+px) {
                left: 70%;
                bottom: -280px;
            }
            @media(max-width: $md6+px) {
                left: auto;
                right: -40px;
                bottom: -250px;
            }
        }
    }
}