@import "../../../styles/_var.scss";
.invest {
    position: relative;
    background: linear-gradient(82.4deg, #0d2421 3.4%, #204b3f 45.25%, #304e3c 96.64%);
    &:after {
        content: "";
        display: "inline-block";
        left: 0;
        top: 0;
        position: absolute;
        width: 601px;
        height: 601px;
        background: url("../../../assets/img/TradingSpacePage/1/1.png") 0 0 / contain no-repeat;
    }
}
.investBody {
    padding: 120px 0 246px 0;
    max-width: 1920px;
    margin: 0 auto;
    position: relative;
    @media (max-width: $md3+px) {
        padding: 120px 0 426px 0;
    }
    @media (max-width: 670px) {
        padding: 100px 0 390px 0;
    }
    @media (max-width: 600px) {
        padding: 80px 0 350px 0;
    }
    @media (max-width: 550px) {
        padding: 70px 0 310px 0;
    }
    @media (max-width: 500px) {
        padding: 60px 0 280px 0;
    }
    @media (max-width: 450px) {
        padding: 50px 0 240px 0;
    }
    &:before {
        content: "";
        display: "inline-block";
        right: 0;
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: url("../../../assets/img/ConditionsPage/1/2.png") right 0 / contain no-repeat;
        @media (max-width: $md1+px) {
            width: 700px;
            background: url("../../../assets/img/ConditionsPage/1/2.png") right 50px / contain no-repeat;
        }
        @media (max-width: $md3+px) {
            background: url("../../../assets/img/ConditionsPage/1/2.png") center bottom / contain no-repeat;
            right: 0;
            left: 0;
            margin: auto;
        }
        @media (max-width: $md5+px) {
            width: 100%;
        }
    }
}
.investTitle {
    font-family: "Cabin", sans-serif;
    font-weight: 600;
    font-size: 64px;
    line-height: 1.3;
    margin-bottom: 10px;
    color: #ffffff;
    position: relative;
    z-index: 3;
    margin-bottom: 20px;
    @media (max-width: $md2+px) {
        font-size: 50px;
    }
    @media (max-width: $md3+px) {
        text-align: center;
    }
    @media (max-width: $md4+px) {
        font-size: 45px;
    }
    @media (max-width: $md5+px) {
        font-size: 40px;
    }
    @media (max-width: $md6+px) {
        font-size: 35px;
    }
}
.investSubtitle {
    color: #bcc9c5;
    font-size: 24px;
    line-height: 1.3;
    max-width: 748px;
    margin-bottom: 40px;
    position: relative;
    z-index: 3;
    @media (max-width: $md2+px) {
        font-size: 20px;
        max-width: 500px;
    }
    @media (max-width: $md3+px) {
        text-align: center;
        margin: 0 auto 40px auto;
    }
    @media (max-width: $md4+px) {
        font-size: 22px;
    }
    @media (max-width: $md5+px) {
        font-size: 20px;
    }
    @media (max-width: $md6+px) {
        font-size: 18px;
    }
}
.investStartBtn {
    // width: 189px;
    // height: 54px;
    // background: #e0ffa3;
    // border-radius: 30px;
    // text-align: center;
    // line-height: 54px;
    // font-size: 20px;
    // cursor: pointer;
    // color: #000000;
    position: relative;
    z-index: 3;
    @media (max-width: $md3+px) {
        margin: 0 auto 0 auto;
    }
    // transition: all 0.3s linear;
    // &:hover {
    //     background-color: #ebffc3;
    // }
}
