@import "../../../styles/_var.scss";
.motivation {
}
.motivationBody {
    margin: 0 0 180px 0;
}
.motivationWrap {
    position: relative;
    @media (max-width: $md4+px) {
        text-align: center;
    }
}
.motivationTitle {
    position: relative;
    z-index: 4;
}

.motivationSubtitle {
    color: #7a7a7a;
    font-size: 24px;
    line-height: 1.4;
    position: relative;
    z-index: 3;
    max-width: 790px;
    margin-bottom: 20px;
    position: relative;
    z-index: 4;
    @media (max-width: $md3+px) {
        max-width: 550px;
    }
    @media (max-width: $md4+px) {
        margin: 0 auto 20px auto;
    }
    @media (max-width: $md5+px) {
        font-size: 22px;
    }
    @media (max-width: $md6+px) {
        font-family: 20px;
    }

    @media (max-width: $md6+px) {
        font-size: 20px;
    }
}

.motivationText {
    color: #4d4d4d;
    font-weight: 600;
    font-size: 32px;
    line-height: 1.4;
    margin-bottom: 60px;
    max-width: 830px;
    position: relative;
    z-index: 4;
    @media (max-width: $md2+px) {
        max-width: 800px;
    }
    @media (max-width: $md3+px) {
        font-size: 28px;
        max-width: 550px;
    }
    @media (max-width: $md4+px) {
        margin: 0 auto 40px auto;
    }
    @media (max-width: $md5+px) {
        font-size: 28px;
    }
    @media (max-width: $md6+px) {
        font-size: 24px;
    }
}
.motivationStart {
    // width: 189px;
    // height: 54px;
    // background: #e0ffa3;
    // border-radius: 30px;
    // line-height: 54px;
    // text-align: center;
    // font-size: 20px;
    // color: #000000;
    cursor: pointer;
    // transition: all 0.3s linear;
    // &:hover {
    //     background-color: #ebffc3;
    // }
    @media (max-width: $md4+px) {
        margin: 0 auto;
    }
}
.motivationFirstImg {
    position: absolute;
    right: 0;
    top: -160px;
    width: 800px;
    z-index: 2;
    transition: all 0.3s linear;
    @media (max-width: $md1+px) {
        width: 600px;
        top: -70px;
        right: -30px;
    }
    @media (max-width: $md3+px) {
        top: 50px;
    }
    @media (max-width: $md4+px) {
        width: 300px;
        left: 0;
        right: 0;
        margin: auto;
        bottom: -270px;
        top: unset;
    }
    &.active {
        &.active {
            transform: rotate(-20deg);
            @media (max-width: $md1+px) {
                transform: rotate(-10deg);
            }
        }
    }
    img {
        width: 100%;
    }
}
.motivationSecondImg {
    position: absolute;
    right: -35px;
    top: 60px;
    width: 450px;
    transition: all 0.3s linear;
    @media (max-width: $md1+px) {
        width: 350px;
        right: -70px;
        top: 90px;
    }
    @media (max-width: $md3+px) {
        top: 200px;
    }
    @media (max-width: $md4+px) {
        width: 150px;
        top: unset;
        bottom: -200px;
        left: 140px;
        right: 0;
        margin: auto;
    }
    &.active {
        transform: rotate(10deg);
        top: 90px;
        @media (max-width: $md1+px) {
            top: 120px;
        }
        @media (max-width: $md3+px) {
            top: 220px;
        }
    }
    z-index: 1;
    img {
        width: 100%;
    }
}
