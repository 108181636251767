@import "../../../styles/_var.scss";

.header {
    position: relative;
    // overflow-x: hidden;
}
.headerBody {
}
.headerRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
}
.headerRowLogo {
    width: 114px;
    position: relative;
    z-index: 10;
    cursor: pointer;
    @media (max-width: $md3+px) {
        width: 80px;
    }
    // &:hover {
    //     img {
    //         transform: scale(1.1);
    //     }
    // }
    img {
        width: 100%;
        transition: all 0.3s linear;
    }
    @media (max-width: $md5+px) {
        &.active {
            img {
                filter: brightness(0) invert(1);
            }
        }
    }
}

.headerRowCenterWrapper {
    @media (max-width: $md4+px) {
        position: absolute;
        z-index: 10;
        top: -50px;
        margin-top: 0px;
        right: 0px;
        pointer-events: none;
        width: 600px;
        height: 650px;

        z-index: 5;
        overflow: hidden;
        display: block;
        text-align: center;
        opacity: 0;
        transition: all 0.3s linear;

        &.active {
            pointer-events: visible;
            opacity: 1;

            top: -50px;
            @media (max-width: $md5+px) {
                top: 0;
            }
        }
    }
    @media (max-width: $md5+px) {
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
    }
}
.headerRowCenter {
    display: flex;
    justify-content: space-between;
    position: relative;

    a {
        font-size: 20px;

        color: #000000;
        transition: all 0.3s linear;
        cursor: pointer;
        position: relative;

        &:after {
            content: "";
            display: "inline-block";
            left: 0;
            bottom: -3px;
            position: absolute;
            width: 0%;
            transition: all 0.3s linear;
            height: 2px;
            background-color: #3da959;
        }

        &:hover {
            color: #3da959;
            &:after {
                width: 100%;
            }
        }
    }

    @media (max-width: $md4+px) {
        display: block;
        width: 100%;
        height: 643px;
        text-align: center;
        padding: 150px 10px 10px 10px;
        background-color: #143f3f;
        a {
            font-size: 32px;
            color: #ffffff;
            display: block;
            margin-bottom: 30px;
            @media (max-width: $md5+px) {
                margin-bottom: 40px;
                font-size: 24px;
            }
            @media (max-height: 600px) {
            }
            @media (max-height: 430px) {
                font-size: 18px;
            }
        }
    }
    @media (max-width: $md5+px) {
        height: 100%;
        padding: 140px 18px 20px 18px;
    }
}
.headerRowCenterItem {
    margin-right: 40px;
    @media (max-width: $md2+px) {
        margin-right: 30px;
    }
    @media (max-width: $md4+px) {
        margin-right: 0px;
    }
}

.headerRowRight {
    display: flex;
    align-items: center;
}
.headerRowRightLocalization {
    margin-right: 20px;
    position: relative;
    z-index: 10;
    @media (max-width: $md2+px) {
        margin-right: 30px;
    }
    @media (max-width: 530px) {
        margin-right: 10px;
    }
}
.headerRowRightLocalizationTitle {
    display: flex;
    align-items: center;
    cursor: pointer;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
    font-size: 20px;
    line-height: 1;
    color: #21211d;
    @media (max-width: $md1+px) {
        font-size: 14px;
    }

    &.active {
        img {
            transform: rotate(180deg);
        }
    }
    img {
        margin-left: 5px;
    }
}
.headerRowRightLocalizationItems {
    position: absolute;
    left: 2px;
    top: 30px;
    background: #fff;
    color: #000;
    padding: 5px 0;
    p {
        padding: 5px 10px;
    }
    display: none;
    &.active {
        display: block;
    }
}
.headerRowRightLogBtn {
    margin-right: 65px;
    @media (max-width: $md2+px) {
        margin-right: 30px;
    }
}

.headerRowRightSignUpBtn {
    // width: 95px;
    padding: 0 20px;
    height: 36px;
    background: #143f3f;
    border-radius: 20px;
    cursor: pointer;
    text-align: center;
    line-height: 36px;
    position: relative;
    z-index: 10;
    transition: all 0.3s linear;
    color: #fff;
    font-size: 16px;
    &.active {
        @media (max-width: $md4+px) {
            color: #000;
            background-color: #fff;
            font-size: 16px;
        }
    }

    transition: all 0.3s linear;
    &:hover {
        color: #000000;
        background-color: #e0ffa3;
    }
}
.headerRowRightLogBtn {
    font-size: 16px;
    transition: all 0.3s linear;
    cursor: pointer;
    &:hover {
        color: #000000;
        background-color: #e0ffa3;
    }
    // width: 95px;
    padding: 0 20px;
    height: 36px;
    border-radius: 20px;
    text-align: center;
    line-height: 36px;
    color: #000;
    margin-right: 28px;
    position: relative;
    z-index: 10;

    @media (max-width: 530px) {
        margin-right: 10px;
    }
    &.active {
        @media (max-width: $md4+px) {
            color: #fff;
        }
    }
}
.btnRow {
    display: none;
    flex-direction: column;
    align-items: center;
    @media (max-width: 550px) {
        display: flex;
    }
}
.headerRowRightLogBtn,
.headerRowRightSignUpBtn {
    @media (max-width: 550px) {
        display: none;
    }
    &.mob {
        @media (max-width: 550px) {
            display: inline-block;
            font-size: 24px;
            padding: 5px 20px;
            height: auto;
            margin: 10px auto 0 auto;
        }
    }
}
.headerRowRightLogBtn {
    // margin-top: 10px;
    @media (max-width: 550px) {
        order: 2;
    }
}
.headerRowRightBurger {
    position: relative;
    height: 23px;
    width: 32px;
    margin-left: 54px;
    z-index: 10;
    display: none;
    cursor: pointer;
    position: relative;
    &:after {
        content: "";
        display: "inline-block";
        left: -10px;
        top: -8px;
        position: absolute;
        width: 50px;
        height: 40px;
    }

    @media (max-width: $md4+px) {
        margin-left: 24px;
        display: block;
    }
    span {
        pointer-events: none;
        position: absolute;
        width: 32px;
        transition: all 0.3s linear;
        height: 2px;
        background-color: #000;
        &:first-child {
            left: 0;
            top: 0;
        }
        &:nth-child(2) {
            top: 0;
            bottom: 0;
            right: 0;
            width: 20px;
            margin: auto;
        }
        &:last-child {
            bottom: 0;
            right: 0;
        }
    }
    &.active {
        span {
            @media (max-width: $md4+px) {
                background-color: #fff;
            }
            &:first-child {
                left: 0;
                top: 11px;

                margin: auto;
                width: 32px;
                transform: rotate(45deg);
            }
            &:nth-child(2) {
                transform: rotate(-45deg);
                width: 32px;
            }
            &:last-child {
                opacity: 0;
            }
        }
    }
}
