@import '../../../styles/var';

.outBenfits {
    margin-top: 180px;
    @media(max-width: $md6+px) {
        margin-top: 150px;
    }
    .text {
        font-weight: 400;
        font-size: 32px;
        line-height: 48px;
        color: rgba(0, 0, 0, 0.7);
        margin: 40px 0;
        width: 790px;
        @media(max-width: $md2+px) {
            font-size: 26px;
            line-height: normal;
            margin: 30px 0;
        }
        @media(max-width: $md4+px) {
            width: 100%;
        }
        @media(max-width: $md6+px) {
            font-size: 16px;
            margin: 15px 0 30px 0;
        }
    }
    .cards {
        display: grid;
        grid-template: repeat(3,0fr)/repeat(3,1fr);
        gap: 75px 40px;
        @media(max-width: $md2+px) {
            gap: 50px 40px;
        }
        @media(max-width: $md3+px) {
            gap: 40px 30px;
        }
        @media(max-width: $md4+px) {
            grid-template: repeat(4,0fr)/repeat(2,1fr);
        }
        @media(max-width: $md6+px) {
            grid-template: repeat(9,0fr)/repeat(1,1fr);
        }
        .card {
            &:hover .cardImage::before {
                box-shadow: inset 0px 0px 0px 0px white;
            }
            &Image {
                position: relative;
                z-index: 2;
                margin-left: 11px;
                &::before {
                    transition: all .3s;
                    content: '';
                    position: absolute;
                    width: 100px;
                    height: 100px;
                    border-radius: 100px;
                    background-color: #E0FFA3;
                    z-index: -1;
                    top: -20px;
                    left: -11px;
                    box-shadow: inset 0px 0px 0px 50px white;
                    @media(max-width: $md2+px) {
                        width: 75px;
                        height: 75px;
                        top: -15px;
                        left: -8px;
                    }
                }
                img {
                    @media(max-width: $md2+px) {
                        width: 60px;
                    }
                }
            }
            &Header {
                margin: 10px 0 20px 0;
                font-family: 'Cabin', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 40px;
                line-height: 49px;
                color: #000000;
                @media(max-width: $md2+px) {
                    font-size: 30px;
                    line-height: normal;
                }
                @media(max-width: $md6+px) {
                    font-size: 20px;
                }
            }
            &Text {
                
                color: rgba(0, 0, 0, 0.7);
            }
        }
    }
}