@import '../../../styles/var';

.accountTypes {
    position: relative;
    margin-top: 180px;
    @media(max-width: $md6+px) {
        margin-top: 150px;
    }
    .title {
        text-align: center;
        @media(max-width: $md6+px) {
            text-align: left;
        }
    }
    .subtitle {
        text-align: center;
        font-family: 'Cabin', sans-serif;
        font-weight: 400;
        font-size: 32px;
        line-height: 125%;
        color: rgba(0, 0, 0, 0.7);
        max-width: 891px;
        margin: 40px auto 60px auto;
        @media(max-width: $md2+px) {
            font-size: 25px;
            max-width: 700px;
        }
        @media(max-width: $md6+px) {
            font-size: 14px;
            text-align: left;
            margin: 20px 0 40px 0;
        }
    }
}
.container {
    position: relative;
    @media(max-width: $md3+px) {
        max-width: 100%!important;
        width: 100%!important;
        padding: 0 20px;
    }
    @media(max-width: $md4+px) {
        padding: 0!important
    }
    .contentWr {
        @media(max-width: $md4+px) {
            overflow: scroll;
            overflow-y: hidden;
            padding-bottom: 20px;
        }
    }
    .content, .headerContent {
        position: relative;
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 27px;
        color: #000000;
        text-align: center;
        @media(max-width: $md1+px) {
            font-size: 18px;
            line-height: 120%;
        }
        @media(max-width: $md2+px) {
            font-size: 16px;
        }
        @media(max-width: $md3+px) {
            font-size: 14px;
            line-height: 19px;
        }
        @media(max-width: $md4+px) {
            width: 1200px;
            padding: 0 40px;
        }
        @media(max-width: $md5+px) {
            padding: 0 10px;
            width: 1100px;
        }
        @media(max-width: $md6+px) {
        }
        .row {
            display: grid;
            grid-template-columns: 300px 1fr 1fr 1fr 1fr;
            gap: 20px;
            @media(max-width: $md1+px) {
                grid-template-columns: 270px 1fr 1fr 1fr 1fr;
            }
            @media(max-width: $md2+px) {
                grid-template-columns: 220px 1fr 1fr 1fr 1fr;
            }
            @media(max-width: $md3+px) {
                grid-template-columns: 170px 1fr 1fr 1fr 1fr;
            }
            @media(max-width: $md4+px) {
                grid-template-columns: 210px 1fr 1fr 1fr 1fr;
            }
            .col {
                &:nth-child(1) {
                    text-align: left;
                    font-weight: 600;
                }
            }
        }
    }
    .content {
        .row {
            &:nth-child(even) .col {
                background: rgba(224, 255, 163, 0.2);
                // padding: 23px 0;
                height: 73px;
                @media(max-width: $md1+px) {
                    height: 65px;
                }
                @media(max-width: $md3+px) {
                    height: 49px;
                }
            }
            &:nth-child(odd) .col {
                background: #FFF;
                // padding: 26px 0;
                height: 79px;
                @media(max-width: $md1+px) {
                    height: 70px;
                }
                @media(max-width: $md3+px) {
                    height: 49px;
                }
            }
            .col {
                display: flex;
                align-items: center;
                justify-content: center;
                &:nth-child(1) {
                    justify-content: flex-start;
                    background: transparent!important;
                }
            }
        }
    }
    .headerContent {
        .row {
            .col {
                background: rgba(224, 255, 163, 0.2);
                text-align: center;
                padding: 25px 5px 22px 5px;
                @media(max-width: $md3+px) {
                    padding: 15px 5px 30px 5px;
                }
                &Icon {
                    width: 80px;
                    @media(max-width: $md1+px) {
                        width: 70px;
                    }
                    @media(max-width: $md2+px) {
                        width: 60px;
                    }
                    @media(max-width: $md3+px) {
                        width: 50px;
                    }
                }
                &Title {
                    font-family: 'Cabin', sans-serif;
                    font-weight: 600;
                    font-size: 32px;
                    line-height: 120%;
                    color: #184037;
                    margin: 20px 0 12px 0;
                    @media(max-width: $md1+px) {
                        font-size: 24px;
                    }
                    @media(max-width: $md3+px) {
                        margin: 20px 0 8px 0;
                        font-size: 20px;
                    }
                }
                &Subtitle {
                    font-family: 'Cabin', sans-serif;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 120%;
                    color: rgba(0, 0, 0, 0.7);
                    @media(max-width: $md1+px) {
                        font-size: 20px;
                    }
                    @media(max-width: $md3+px) {
                        font-size: 16px;
                    }
                }
                &Text {
                    color: rgba(0, 0, 0, 0.7);
                    margin: 20px 0 30px 0;
                    @media(max-width: $md3+px) {
                        margin: 20px 0;
                    }
                }
                &Btn {
                    margin-top: 30px;
                    @media(max-width: $md3+px) {
                        margin-top: 20px;
                    }
                    @media(max-width: $md1+px) {
                        font-size: 18px!important;
                        line-height: 24px!important;
                    }
                    @media(max-width: $md2+px) {
                        font-size: 16px!important;
                        line-height: 22px!important;
                    }
                    @media(max-width: $md3+px) {
                        font-size: 14px!important;
                        line-height: 19px!important;
                    }
                }
            }
        }
    }
}