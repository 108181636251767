@import "../../../styles/_var.scss";
.first {
    background: linear-gradient(273.24deg, #0d2421 -22.38%, #204b3f 55.18%, #2b5840 110.58%);
    position: relative;
    &:after {
        content: "";
        display: "inline-block";
        left: 0;
        top: 0;
        position: absolute;
        width: 601px;
        height: 601px;
        background: url("../../../assets/img/TradingSpacePage/1/1.png") 0 0 / contain no-repeat;
    }
}
.firstBody {
    padding: 80px 0 112px 0;
    position: relative;
    max-width: 1920px;
    margin: 0 auto;
    @media (max-width: $md3+px) {
        padding-bottom: 450px;
    }
    @media (max-width: 670px) {
        padding-bottom: 400px;
    }
    @media (max-width: 620px) {
        padding-bottom: 350px;
    }
    @media (max-width: 550px) {
        padding-bottom: 320px;
    }
    @media (max-width: 500px) {
        padding-bottom: 280px;
    }
    @media (max-width: 450px) {
        padding-bottom: 250px;
    }
    &:before {
        content: "";
        display: "inline-block";
        right: 0;
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: url("../../../assets/img/TradingSpacePage/1/2.png") right 0 / contain no-repeat;
        @media (max-width: $md1+px) {
            width: 700px;
            background: url("../../../assets/img/TradingSpacePage/1/2.png") right 50px / contain no-repeat;
        }
        @media (max-width: $md3+px) {
            background: url("../../../assets/img/TradingSpacePage/1/2.png") center bottom / contain no-repeat;
            right: 0;
            left: 0;
            margin: auto;
        }
        @media (max-width: $md5+px) {
            width: 100%;
        }
    }
}
.firstTitle {
    font-family: "Cabin", sans-serif;
    font-weight: 600;
    font-size: 64px;
    line-height: 1.3;
    margin-bottom: 10px;
    color: #ffffff;
    position: relative;
    z-index: 3;
    @media (max-width: $md2+px) {
        font-size: 50px;
    }
    @media (max-width: $md3+px) {
        text-align: center;
    }
    @media (max-width: $md4+px) {
        font-size: 45px;
    }
    @media (max-width: $md5+px) {
        font-size: 40px;
    }
    @media (max-width: $md6+px) {
        font-size: 35px;
    }
}
.firstSubtitle {
    font-family: "Cabin", sans-serif;
    font-weight: 600;
    font-size: 40px;
    line-height: 1.3;
    color: #ffffff;
    max-width: 652px;
    margin-bottom: 20px;
    position: relative;
    z-index: 3;
    @media (max-width: $md2+px) {
        font-size: 35px;
        max-width: 600px;
    }
    @media (max-width: $md3+px) {
        text-align: center;
        margin: 0 auto 20px auto;
    }
    @media (max-width: $md4+px) {
        font-size: 35px;
    }
    @media (max-width: $md5+px) {
        font-size: 30px;
    }
    @media (max-width: $md6+px) {
        font-size: 25px;
    }
}
.firstText {
    color: #bcc9c5;
    font-size: 24px;
    line-height: 1.3;
    max-width: 748px;
    margin-bottom: 40px;
    position: relative;
    z-index: 3;
    @media (max-width: $md2+px) {
        font-size: 20px;
        max-width: 500px;
    }
    @media (max-width: $md3+px) {
        text-align: center;
        margin: 0 auto 40px auto;
    }
    @media (max-width: $md4+px) {
        font-size: 22px;
    }
    @media (max-width: $md5+px) {
        font-size: 20px;
    }
    @media (max-width: $md6+px) {
        font-size: 18px;
    }
}

.firstStart {
    // width: 189px;
    // height: 54px;
    // background: #e0ffa3;
    // border-radius: 30px;
    // text-align: center;
    // line-height: 54px;
    // font-size: 20px;
    // cursor: pointer;
    // color: #000000;
    position: relative;
    z-index: 3;
    @media (max-width: $md3+px) {
        margin: 0 auto 0 auto;
    }
    // transition: all 0.3s linear;
    // &:hover {
    //     background-color: #ebffc3;
    // }
}
