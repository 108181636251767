@import "../../../styles/_var.scss";
.traders {
    padding-top: 120px;
    @media (max-width: $md6+px) {
        padding-top: 100px;
    }
}
.tradersBody {
}
.tradersTitle {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    font-size: 32px;
    font-weight: 600;
    img {
        margin-right: 10px;
        position: relative;
        top: -5px;
    }
    @media (max-width: $md2+px) {
        font-size: 28px;
    }

    @media (max-width: $md5+px) {
        font-size: 24px;
    }
    @media (max-width: $md6+px) {
        font-size: 18px;
    }
}
.tradersSubtitle {
    text-align: center;
    margin-bottom: 60px;
    @media (max-width: $md2+px) {
        padding: 0 20px;
    }
    @media (max-width: $md4+px) {
        margin-bottom: 30px;
    }
}
.tradersSlider {
    box-shadow: 0px 0px 30px rgba(11, 101, 85, 0.05);
    position: relative;
    &:after {
        content: "";
        display: inline-block;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(11, 101, 85, 0.05);
        opacity: 0.5;
    }
}
.tradersSliderSlide {
    background: #ffffff;
    border: 1px solid #f2f2f2;
    border-radius: 8px;
    padding: 40px 40px 80px 40px;
    height: 100%;
    @media (max-width: $md1+px) {
        padding: 20px 20px 40px 20px;
    }
}
.tradersSliderSlideItem {
}
.tradersSliderSlideItemTop {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.tradersSliderSlideItemTopIcon {
    margin-right: 30px;
    img {
        width: 60px;
        border-radius: 50%;
    }
    @media (max-width: $md2+px) {
        margin-right: 15px;
    }
}

.tradersSliderSlideItemTopTitle {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.1;
    color: #000000;
    margin-right: 30px;
    @media (max-width: $md1+px) {
        font-size: 22px;
    }
    @media (max-width: $md2+px) {
        margin-right: 15px;
        font-size: 20px;
    }
}
.tradersSliderSlideItemTopStars {
}
.tradersSliderSlideItemText {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.7);
    @media (max-width: $md4+px) {
        font-size: 16px;
    }
}

.tradersSliderSlideItemTopStars {
    min-width: 100px;
    width: 100px;
    background-color: #d0d0d0;
    height: 18px;
    display: flex;
    position: relative;
}
.tradersSliderSlideItemTopStarsProgress {
    left: 0;
    top: 0;
    position: absolute;

    height: 100%;

    &1 {
        background-color: #ff3622;
    }
    &2 {
        background-color: #ff8622;
    }
    &3 {
        background-color: #fece00;
    }
    &4 {
        background-color: #72cf12;
    }
    &5 {
        background-color: #00b67b;
    }
}
.tradersSliderSlideItemTopStarsStar {
    width: 20%;
    height: 100%;
    position: relative;
    &:after {
        content: "";
        display: inline-block;
        left: 0;
        right: 0px;
        top: 0px;
        bottom: 0;
        margin: auto;
        position: absolute;
        width: 14px;
        height: 12px;
        background: url("../../../assets/img/MainPage/OurTraders/star.png") center / contain no-repeat;
    }
    position: relative;
    &:before {
        content: "";
        display: inline-block;
        right: 0;
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        border-right: 1px solid #fff;
    }
}
