@import "../../../styles/_var.scss";
.have {
}
.haveBody {
    margin-bottom: 150px;
}
.top {
}
.topRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
}
.topRowLeft {
    @media (max-width: $md3+px) {
        flex: 0 1 60%;
    }
    @media (max-width: $md5+px) {
        flex: 0 1 100%;
        text-align: center;
    }
}
.topRowLeftTitle {
}
.topRowLeftSubtitle {
    color: #4d4d4d;
    max-width: 790px;
    font-size: 24px;
    line-height: 1.4;
    @media (max-width: $md5+px) {
        font-size: 22px;
    }
    @media (max-width: $md5+px) {
        font-size: 20px;
    }
}

.topRowRight {
    @media (max-width: $md3+px) {
        img {
            width: 300px;
        }
    }
    @media (max-width: $md4+px) {
        img {
            width: 200px;
        }
    }
    @media (max-width: $md5+px) {
        display: none;
    }
}
.botRow {
    height: 83px;
    display: flex;
    align-items: center;
    padding-left: 138px;
    border-radius: 20px;
    transition: all 0.3s linear;
    cursor: pointer;
    &:hover {
        transform: scale(1.03);
    }
    @media (max-width: $md4+px) {
        flex-wrap: wrap;
    }
    &:nth-child(even) {
        background-color: #fcfcfc;
    }
    @media (max-width: $md2+px) {
        padding-left: 60px;
    }
    @media (max-width: $md4+px) {
        padding: 20px 30px;
        height: auto;
    }
    @media (max-width: $md6+px) {
        padding: 20px 10px;
    }
}
.botRowLeft {
    margin-right: 300px;
    @media (max-width: $md1+px) {
        margin-right: 200px;
    }
    @media (max-width: $md2+px) {
        margin-right: 100px;
    }
    @media (max-width: $md4+px) {
        margin-right: 0;
        margin-bottom: 30px;
    }
}
.botRowLeft,
.botRowRight {
    display: flex;
    justify-content: space-between;
    width: 400px;
    @media (max-width: $md4+px) {
        width: 100%;
    }
}
.botRowLeftName,
.botRowRightName {
    font-weight: 300;
    font-size: 24px;
    line-height: 1;
    color: #000000;
    @media (max-width: $md5+px) {
        font-size: 22px;
    }
    @media (max-width: $md6+px) {
        font-size: 20px;
    }
}
.botRowLeftPrice,
.botRowRightPrice {
    font-weight: 600;
    font-size: 24px;
    line-height: 1;
    color: #000000;
    @media (max-width: $md5+px) {
        font-size: 22px;
    }
    @media (max-width: $md6+px) {
        font-size: 20px;
    }
}
.botRowRightName {
}
.botRowRightPrice {
}
