@import "../../../styles/_var.scss";
#myTradersSlider {
    // background-color: red !important;
    width: 2170px !important;
    position: relative;
    z-index: 10;
    margin: 0 auto;
    @media (max-width: $md2+px) {
        width: 1800px !important;
    }
    @media (max-width: $md6+px) {
        width: 375px !important;
    }
}
.swiper-slide {
    height: auto;
}
.swiper-pagination1 {
    z-index: 20;
    margin-top: 30px;
    display: none;
    @media (max-width: $md4+px) {
        display: flex;
    }
    justify-content: center;
    .swiper-pagination-bullet {
        width: 40px;
        opacity: 0.2;
        z-index: 10;
        height: 3px;
        margin: 0 5px;
        border-radius: unset;
        background-color: #03b579;
        position: relative;
        &:after {
            content: "";
            display: "inline-block";
            left: 0;
            top: -20px;
            position: absolute;
            width: 150%;
            height: 40px;
            z-index: 5;
            opacity: 0;
        }
        &.swiper-pagination-bullet-active {
            opacity: 1;
        }
    }
}

// #myAboutSlider .swiper {
//     overflow: visible;
// }
