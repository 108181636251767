@import '../../../styles/var';

.whatDiffers {
    margin-top: 180px;
    @media(max-width: $md6+px) {
        margin-top: 100px;
    }
    .header {
        text-align: center;
        @media(max-width: $md5+px) {
            text-align: left;
        }
    }
    .cards {
        display: flex;
        justify-content: space-between;
        margin-top: 60px;
        @media(max-width: $md5+px) {
            display: block;
        }
        @media(max-width: $md6+px) {
            margin-top: 30px;
        }
        .card {
            width: 31.5%;
            @media(max-width: $md5+px) {
                width: 100%;
                margin-bottom: 40px;
            }
            @media(max-width: $md6+px) {
                margin-bottom: 20px;
            }
            &:hover img {
                scale: 1.1;
            }
            &Icon {
                img {
                    transition: all .2s;
                    @media(max-width: $md2+px) {
                        width: 80px;
                    }
                    @media(max-width: $md6+px) {
                        width: 60px;
                    }
                }
                text-align: center;
            }
            &Header {
                text-align: center;
                margin: 30px 0 40px 0;
                font-family: 'Cabin', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 40px;
                line-height: 49px;
                @media(max-width: $md2+px) {
                    font-size: 30px;
                    line-height: normal;
                    margin: 20px 0 30px 0;
                }
                @media(max-width: $md5+px) {
                    margin: 10px 0;
                }
                @media(max-width: $md6+px) {
                    font-size: 26px;
                }
            }
            &Content {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 36px;
                color: rgba(0, 0, 0, 0.7);
                @media(max-width: $md2+px) {
                    font-size: 20px;
                    line-height: normal;
                }
                @media(max-width: $md6+px) {
                    font-size: 14px;
                }
            }
            .btn {
                cursor: pointer;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 700;
                font-size: 15px;
                line-height: 36px;
                color: rgba(0, 0, 0, 0.7);
                margin-top: 15px;
                position: relative;
                display: inline-flex;
                @media(max-width: $md5+px) {
                    margin-top: 10px;
                }
                @media(max-width: $md6+px) {
                    font-size: 12px;
                    margin-top: 0px;
                }
                &:hover {
                    color: rgba(0, 0, 0, 0.5);
                }
                &::after {
                    content: '';
                    position: absolute;
                    background-image: url('../../../assets/img/MainPage/arrow.svg');
                    background-position: center;
                    background-size: cont;
                    background-repeat: no-repeat;
                    width: 8px;
                    height: 8px;
                    top: 15px;
                    right: -15px;
                    @media(max-width: $md6+px) {
                        right: -10px;
                    }
                }
            }
        }
    }
}