@import '../../../styles/var';

.instruments {
    margin-top: 180px;
    @media(max-width: $md6+px) {
        margin-top: 150px;
    }
    .header {

    }
    .cards {
        margin-top: 60px;
        display: grid;
        grid-template: repeat(2,1fr)/repeat(2,1fr);
        gap: 40px;
        @media(max-width: $md3+px) {
            gap: 20px;
        }
        @media(max-width: $md5+px) {
            grid-template: repeat(4,1fr)/repeat(1,1fr);
            margin-top: 40px;
        }
        @media(max-width: $md6+px) {
            margin-top: 30px;
        }
        .card {
            overflow: hidden;
            width: 100%;
            border: 1px solid #F2F2F2;
            box-shadow: 0px 0px 30px rgba(11, 101, 85, 0.05);
            border-radius: 8px;
            padding: 40px;
            &:hover .cardIcon::before {
                scale: 20;
                @media(max-width: $md5+px) {
                    scale: 25;
                }
            }
            @media(max-width: $md4+px) {
                padding: 30px;
            }
            @media(max-width: $md6+px) {
                padding: 20px;
            }
            &Icon {
                position: relative;
                z-index: 2;
                &::before {
                    transition: all 1s;
                    content: '';
                    position: absolute;
                    background-color: #E0FFA3;
                    width: 100px;
                    height: 100px;
                    z-index: -1;
                    border-radius: 100px;
                    top: -19px;
                    left: -19px;
                    @media(max-width: $md3+px) {
                        width: 60px;
                        height: 60px;
                        top: -10px;
                        left: -10px;
                    }
                }
                img {
                    @media(max-width: $md3+px) {
                        width: 40px;
                    }
                }
            }
            &Header {
                position: relative;
                z-index: 3;
                font-family: 'Cabin', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 40px;
                line-height: 49px;
                margin: 40px 0 20px 0;
                @media(max-width: $md2+px) {
                    font-size: 30px;
                    line-height: normal;
                    margin: 40px 0 15px 0;
                }
                @media(max-width: $md3+px) {
                    margin: 25px 0 15px 0;
                }
                @media(max-width: $md6+px) {
                    font-size: 24px;
                    margin: 15px 0 10px 0;
                }
            }
            &Text {
                position: relative;
                z-index: 3;
                line-height: 36px;
                color: rgba(0, 0, 0, 0.7);
                @media(max-width: $md2+px) {
                    line-height: normal;
                }
            }
        }
    }
}