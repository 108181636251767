@import '../../../styles/var';

.referFriend {
    position: relative;
    margin-top: 180px;
    @media(max-width: $md6+px) {
        margin-top: 150px;
    }
    .header {
        text-align: center;
        max-width: 676px;
        margin: 0 auto;
        @media(max-width: $md6+px) {
            text-align: left;
        }
        .subtitle {
            font-family: 'Open Sans', sans-serif;
            font-weight: 400;
            font-size: 32px;
            line-height: 135%;
            color: #2A6056;
            margin: 10px 0 20px 0;
            @media(max-width: $md2+px) {
                font-size: 24px;
            }
        }
        .text_01 {
            font-weight: 600;
            color: #0D2421;
            @media(max-width: $md6+px) {
                font-size: 16px!important;
            }
        }
        .text_02 {
            font-family: 'Open Sans', sans-serif;
            font-weight: 300;
            font-size: 20px;
            line-height: 135%;
            color: #000000;
            margin: 10px 0 15px 0;
            @media(max-width: $md6+px) {
                font-size: 14px!important;
            }
        }
        .text_03 {
            font-weight: 400;
            color: rgba(0, 0, 0, 0.7);
            @media(max-width: $md6+px) {
                font-size: 16px!important;
            }
        }
    }
    .cards {
        position: relative;
        margin: 40px 0 30px 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        @media(max-width: $md5+px) {
            grid-template-columns: repeat(1, 1fr);
        }
        .card {
            position: relative;
            background: #FFFFFF;
            border: 1px solid #F2F2F2;
            box-shadow: 0px 0px 30px rgba(11, 101, 85, 0.05);
            border-radius: 8px;
            padding: 55px;
            transition: all .3s;
            &:hover {
                transform: scale(0.97);
            }
            @media(max-width: $md1+px) {
                padding: 45px 20px;
            }
            @media(max-width: $md4+px) {
                padding: 30px 20px 50px 20px;
            }
            @media(max-width: $md5+px) {
                width: 350px;
                margin: 0 auto;
            }
            &Image {
                position: absolute;
                z-index: 1;
                width: auto;
                height: 200px;
                bottom: 0;
                left: 40px;
                @media(max-width: $md1+px) {
                    height: 150px;
                    left: 30px;
                }
                @media(max-width: $md2+px) {
                    height: 120px;
                }
                @media(max-width: $md3+px) {
                    height: 100px;
                    left: 20px;
                }
                @media(max-width: $md4+px) {
                    height: 70px;
                    left: 10px;
                }
                @media(max-width: $md5+px) {
                    height: 100px;
                }
            }
            .cardText {
                position: relative;
                z-index: 3;
                text-align: center;
                max-width: 230px;
                margin-left: auto;
                @media(max-width: $md3+px) {
                    max-width: 200px;
                }
                @media(max-width: $md4+px) {
                    max-width: 100%;
                }
            }
            &Text_01 {
                font-weight: 700;
                color: #2A6056;
                @media(max-width: $md6+px) {
                    font-size: 16px;
                }
            }
            &Text_02 {
                margin-top: 40px;
                font-family: 'Open Sans', sans-serif;
                font-weight: 400;
                font-size: 20px;
                line-height: 150%;
                color: rgba(0, 0, 0, 0.7);
                @media(max-width: $md2+px) {
                    font-size: 14px;
                    margin-top: 20px;
                }
            }
            &Text_03 {
                margin: 6px 0 10px 0;
                font-family: 'Open Sans', sans-serif;
                font-weight: 600;
                font-size: 36px;
                line-height: 150%;
                color: #A1CE49;
                @media(max-width: $md2+px) {
                    font-size: 24px;
                }
            }
            &Text_04 {
                font-family: 'Open Sans', sans-serif;
                font-weight: 400;
                font-size: 20px;
                line-height: 150%;
                color: rgba(0, 0, 0, 0.7);
                @media(max-width: $md2+px) {
                    font-size: 14px;
                }
            }
        }
    }
    .desc {
        text-align: center;
        font-family: 'Open Sans', sans-serif;
        font-weight: 300;
        font-size: 20px;
        line-height: 150%;
        color: rgba(0, 0, 0, 0.7);
        @media(max-width: $md2+px) {
            font-size: 14px;
        }
    }
}