@import "../../../styles/_var.scss";
.hours {
}
.hoursBody {
}
.hoursTitle {
    @media (max-width: $md4+px) {
        text-align: center;
    }
}

.hoursSubtitle {
    color: #4d4d4d;
    font-size: 24px;
    line-height: 1.4;
    max-width: 1000px;
    @media (max-width: $md2+px) {
        font-size: 22px;
    }
    @media (max-width: $md6+px) {
        font-size: 20px;
    }
    @media (max-width: $md4+px) {
        text-align: center;
    }
}

.hoursText {
    margin-bottom: 60px;
    color: #4d4d4d;
    font-size: 24px;
    line-height: 1.4;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 40px;
    max-width: 1000px;
    b {
        font-weight: 600;
    }
    @media (max-width: $md2+px) {
        font-size: 22px;
    }
    @media (max-width: $md6+px) {
        font-size: 20px;
    }
    @media (max-width: $md4+px) {
        text-align: center;
    }
}

.standart {
}
.standartBody {
    margin-bottom: 180px;
}
.standartTitle {
    @media (max-width: $md4+px) {
        text-align: center;
    }
}
.standartTabs {
    height: 68px;
    background: #e7e7e7;
    border-radius: 20px;
    @media (max-width: $md6+px) {
        height: 40px !important;
    }
    &.active {
        background: #184039;
        border-radius: 20px;
    }
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}
.standartTabsTab {
    flex: 0 1 50%;
    font-family: "Cabin", sans-serif;
    font-weight: 400;
    text-align: center;
    font-size: 40px;
    border-radius: 20px;
    line-height: 68px;
    color: #767676;
    @media (max-width: $md4+px) {
        font-size: 30px;
    }
    @media (max-width: $md5+px) {
        font-size: 22px;
    }
    @media (max-width: $md6+px) {
        font-size: 14px;
    }
    &.active {
        background-color: #184039;
        color: #fff;
    }
    @media (max-width: $md6+px) {
        line-height: 40px;
    }
}
.standartContent {
    display: none;
    &.active {
        display: block;
    }
    @media (max-width: $md4+px) {
        overflow: auto;
    }
}
.standartContentTop {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    padding-left: 93px;
    @media (max-width: $md4+px) {
        width: 992px;
    }
}
.standartContentTopColumn {
    font-weight: 700;
    font-size: 24px;
    line-height: 1.2;
    color: #000000;
    flex: 0 1 500px;
    @media (max-width: $md2+px) {
        font-size: 20px;
        flex: 0 1 350px;
    }
    @media (max-width: $md5+px) {
        font-size: 16px;
    }
    @media (max-width: $md6+px) {
        font-size: 14px;
    }
}
.tabsContent {
}
.standartContentRow {
    display: flex;

    border-radius: 20px;

    padding: 20px 0 20px 93px;
    transition: all 0.4s linear;
    justify-content: space-between;
    @media (max-width: $md4+px) {
        width: 992px;
    }
    &:hover {
        transform: scale(1.02);
    }
    &:nth-child(even) {
        background: #fcfcfc;
    }
    position: relative;
    img {
        margin-right: 33px;
        position: absolute;
        left: 33px;
        top: 28px;
        height: 20px;
        @media (max-width: $md2+px) {
            top: 24px;
        }
        @media (max-width: $md5+px) {
            top: 20px;
        }
    }
}
.standartContentRowColumn {
    font-weight: 400;
    font-size: 24px;
    line-height: 1.3;
    color: #000000;
    flex: 0 1 500px;
    @media (max-width: $md2+px) {
        font-size: 20px;
        flex: 0 1 350px;
    }
    @media (max-width: $md5+px) {
        font-size: 16px;
    }
    @media (max-width: $md6+px) {
        font-size: 14px;
    }
    // &:nth-child(3) {
    //     margin-right: 100px;
    //     flex: 0 1 190px;
    // }
}
.wrapText {
    max-width: 190px;
}
.standartStart {
    width: 283px;
    height: 77px;
    margin: 40px auto 0 auto;
    background: #e0ffa3;
    border-radius: 50px;
    font-size: 32px;
    line-height: 77px;
    text-align: center;
    cursor: pointer;
    color: #000000;
    @media (max-width: $md5+px) {
        width: 230px;
        height: 50px;
        line-height: 50px;
        font-size: 20px;
    }
    @media (max-width: $md6+px) {
        font-size: 16px;
    }
    transition: all 0.3s linear;
    &:hover {
        background-color: #ebffc3;
    }
}
