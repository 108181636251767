@font-face {
    font-family: "Open Sans";
    src: url("../assets/fonts/OpenSans-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "Open Sans";
    src: url("../assets/fonts/OpenSans-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "Open Sans";
    src: url("../assets/fonts/OpenSans-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "Open Sans";
    src: url("../assets/fonts/OpenSans-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: "Open Sans";
    src: url("../assets/fonts/OpenSans-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "Open Sans";
    src: url("../assets/fonts/OpenSans-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}


@font-face {
    font-family: "Cabin";
    src: url("../assets/fonts/Cabin-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Cabin";
    src: url("../assets/fonts/Cabin-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "Cabin";
    src: url("../assets/fonts/Cabin-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: "Cabin";
    src: url("../assets/fonts/Cabin-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}