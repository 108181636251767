@import '../../../styles/var';

.banner {
    position: relative;
    background: linear-gradient(272.07deg, #0D2421 -6.87%, #204B3F 52.89%, #2B5840 95.58%);
    .light_01 {
        position: absolute;
        top: 0;
        right: 20%;
        z-index: 1;
    }
    .light_02 {
        position: absolute;
        right: 5%;
        top: 60px;
        z-index: 1;
    }
    .content {
        padding: 120px 0 156px 0;
        position: relative;
        @media(max-width: $md2+px) {
            padding: 100px 0 130px 0;
        }
        @media(max-width: $md5+px) {
            padding-bottom: 0;
        }
        .header {
            color: white;
            position: relative;
            z-index: 4;
        }
        .second {
            font-family: 'Cabin', sans-serif;
            margin-top: 10px;
            color: white;
            font-weight: 600;
            font-size: 40px;
            line-height: 49px;
            position: relative;
            z-index: 4;
            @media(max-width: $md2+px) {
                font-size: 30px;
                line-height: normal;
            }
            @media(max-width: $md6+px) {
                font-size: 24px;
            }
        }
        .text {
            margin: 20px 0 40px 0;
            width: 526px;
            color: rgba(255, 255, 255, 0.7);
            position: relative;
            z-index: 4;
            @media(max-width: $md2+px) {
                width: 350px;
                margin: 15px 0 30px 0;
            }
            @media(max-width: $md5+px) {
                margin: 10px 0 20px 0;
            }
        }
        .btn {
            position: relative;
            z-index: 4;
            @media(min-width: $md3+px) {
                &:hover {
                    & ~ .animals .bull {
                        right: 28%;
                        @media(max-width: $md1+px) {
                            right: 18%;
                        }
                    }
                    & ~ .animals .bear {
                        right: -80px;
                        @media(max-width: $md1+px) {
                            right: -180px;
                        }
                        @media(max-width: $md2+px) {
                            right: -130px;
                        }
                    }
                }
            }
        }
        .animals {
            @media(max-width: $md5+px) {
                display: flex;
                // height: 200px;
                position: relative;
            }
            img {
                position: absolute;
                transition: all 1s;
                @media(max-width: $md5+px) {
                    position: relative;
                }
            }
            .bull {
                top: 126px;
                right: 30%;
                z-index: 3;
                @media(max-width: $md1+px) {
                    scale: 0.8;
                    right: 20%;
                }
                @media(max-width: $md2+px) {
                    scale: 0.7;
                    top: 100px;
                    right: 20%;
                }
                @media(max-width: $md4+px) {
                    scale: 0.5;
                    right: 5%;
                    top: 110px;
                }
                @media(max-width: $md5+px) {
                    display: none;
                    // scale: 1;
                    // right: none;
                    // left: -100px;
                }
            }
            .bear {
                top: 171px;
                right: -100px;
                z-index: 2;
                @media(max-width: $md1+px) {
                    scale: 0.8;
                    right: -200px;
                }
                @media(max-width: $md2+px) {
                    scale: 0.7;
                    right: -150px;
                    top: 150px;
                }
                @media(max-width: $md4+px) {
                    scale: 0.5;
                    right: -200px;
                }
                @media(max-width: $md5+px) {
                    display: none;
                }
            }
            .tog {
                display: none;
                @media(max-width: $md5+px) {
                    display: flex;
                    width: 90%;
                    position: relative;
                    z-index: 2;
                    top: -90px;
                }
                @media(max-width: $md6+px) {
                    top: -50px;
                    width: 100%;
                }
            }
        }
    }
}