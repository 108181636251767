@import "../../../styles/_var.scss";
.order {
}
.orderBody {
    margin: 180px 0;
}
.orderTitle {
    @media (max-width: $md3+px) {
        text-align: center;
    }
}

.orderSubtitle {
    color: rgba(0, 0, 0, 0.7);
    font-size: 24px;
    line-height: 1.4;
    position: relative;
    z-index: 3;
    max-width: 790px;
    margin-bottom: 60px;
    @media (max-width: $md3+px) {
        text-align: center;
        margin: 0 auto 180px auto;
    }
    @media (max-width: $md5+px) {
        font-size: 22px;
    }
    @media (max-width: $md6+px) {
        font-family: 20px;
    }
}

.example {
    position: relative;
    padding: 40px 120px 60px 120px;
    @media (max-width: $md4+px) {
        padding: 40px 30px 60px 30px;
    }
    @media (max-width: $md6+px) {
        padding: 30px 10px 30px 10px;
    }
    &:after {
        content: "";
        display: "inline-block";
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #000;
        background: rgba(255, 255, 255, 0.4);
        border: 1px solid #f2f2f2;
        box-shadow: 0px 0px 30px rgba(11, 101, 85, 0.05);
        backdrop-filter: blur(5px);
        border-radius: 8px;
    }
}
.exampleTitle {
    position: relative;
    z-index: 2;
    margin-bottom: 50px;
    text-align: center;
    font-family: "Cabin", sans-serif;
    font-weight: 600;
    font-size: 40px;
    line-height: 1;
    color: #000000;
    @media (max-width: $md5+px) {
        font-size: 35px;
        margin-bottom: 40px;
    }
    @media (max-width: $md6+px) {
        font-size: 30px;
        margin-bottom: 30px;
    }
}
.exampleRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    position: relative;
    z-index: 2;
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
    }
}
.exampleRowLeft {
    background: #e0ffa3;
    border-radius: 20px;
    flex: 0 1 533px;
    padding: 35px 30px;
    @media (max-width: $md1+px) {
        flex: 0 1 440px;
    }
    @media (max-width: $md3+px) {
        flex: 0 1 100%;
        margin-bottom: 20px;
    }
    @media (max-width: $md5+px) {
        padding: 25px 20px;
    }
    @media (max-width: $md6+px) {
        padding: 15px 10px;
    }
}
.exampleRowLeftText {
    font-size: 32px;
    line-height: 1.4;
    text-align: center;

    color: #184037;
    @media (max-width: $md5+px) {
        font-size: 28px;
    }
    @media (max-width: $md6+px) {
        font-size: 24px;
    }
}

.exampleRowLeftImg {
    text-align: center;
    margin: 10px 0;
}

.exampleRowRight {
    flex: 0 1 777px;
    @media (max-width: $md1+px) {
        flex: 0 1 600px;
    }
    @media (max-width: $md2+px) {
        flex: 0 1 500px;
    }
    @media (max-width: $md3+px) {
        flex: 0 1 100%;
        text-align: center;
    }
}
.exampleRowRightText {
    color: #4d4d4d;
    font-size: 24px;
    line-height: 1.3;
    margin-bottom: 20px;
    @media (max-width: $md5+px) {
        font-size: 22px;
    }
    @media (max-width: $md6+px) {
        font-size: 20px;
        margin-bottom: 0px;
    }
}

.exampleRowSubtext {
    position: relative;
    z-index: 2;
    color: #4d4d4d;
    text-align: center;
    font-weight: 600;
    font-size: 32px;
    max-width: 1000px;
    margin: 0 auto;
    line-height: 1.4;
    @media (max-width: $md5+px) {
        font-size: 28px;
    }
    @media (max-width: $md6+px) {
        font-size: 24px;
    }
}
.exampleFirstImg {
    width: 520.85px;
    height: 486.11px;
    right: -90px;
    top: -275px;
    position: absolute;
    transition: all 0.3s linear;
    img {
        width: 100%;
    }
    @media (max-width: $md4+px) {
        transform: scale(0.7);
        top: -265px;
    }
}
.exampleSecondImg {
    width: 421.24px;
    height: 319.86px;
    left: -40px;
    bottom: -35px;
    position: absolute;
    img {
        width: 100%;
    }
    @media (max-width: $md4+px) {
        transform: scale(0.7);
        bottom: -66px;
    }
    transition: all 0.3s linear;
    &:hover {
        left: -20px;
        bottom: -15px;
    }
}
.example {
    &:hover {
        .exampleSecondImg {
            left: -60px;
            bottom: -105px;
            transform: rotate(10deg);
        }
        .exampleFirstImg {
            right: -40px;
            top: -375px;
            transform: rotate(10deg);
        }
    }
}
