@import '../../styles/var';

.gradient {
    background: linear-gradient(33.07deg, #0D2421 39.43%, #204B3F 82.35%, #2B5840 113%);
    .light {
        background-image: url('../../assets/img/MainPage/banner_light.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 481px;
        height: 481px;
        position: absolute;
        top: 0;
        left: 0;
        @media(max-width: $md5+px) {
            width: 300px;
            height: 300px;
        }
    }
}

.bl {
    width: 100%;
    background-color: grey;
    height: 300px;
    margin-top: 200px;
    padding-top: 50px;
    position: relative;
}