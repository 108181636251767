@import "../../../styles/_var.scss";
.indices {
}
.indicesBody {
    padding: 100px 0 180px 0;
    @media (max-width: $md4+px) {
        padding: 80px 0 150px 0;
    }
    @media (max-width: $md5+px) {
        padding: 60px 0 100px 0;
    }
}
.indicesTitle {
}
.indicesRow {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.indicesRowColumn {
    flex: 0 1 50%;
    &:first-child {
        margin-bottom: 60px;
    }
    @media (max-width: $md4+px) {
        flex: 0 1 100%;
    }
    @media (max-width: $md4+px) {
        margin-bottom: 30px !important;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &:hover {
        .indicesRowColumnIcon {
            transform: scale(1.1);
        }
    }
}
.indicesRowColumnWrap {
    max-width: 500px;
    @media (max-width: $md3+px) {
        max-width: 310px;
    }
    @media (max-width: $md4+px) {
        max-width: 100%;
        text-align: center;
    }
}
.indicesRowColumnIcon {
    margin-bottom: 20px;
    width: 100px;

    transition: all 0.3s linear;

    @media (max-width: $md4+px) {
        margin: 0 auto 20px auto;
    }
    img {
        width: 100%;
    }
    @media (max-width: $md4+px) {
        width: 80px;
    }
    @media (max-width: $md5+px) {
        width: 60px;
    }
}
.indicesRowColumnTitle {
    font-family: "Cabin", sans-serif;
    font-weight: 600;
    font-size: 40px;
    line-height: 1.2;
    color: #000000;
    margin-bottom: 20px;
    @media (max-width: $md4+px) {
        font-size: 35px;
    }
    @media (max-width: $md5+px) {
        font-size: 30px;
    }
}
.indicesRowColumnSubtitle {
    font-size: 24px;
    line-height: 1.4;
    color: #4d4d4d;
    @media (max-width: $md4+px) {
        font-size: 22px;
    }
    @media (max-width: $md5+px) {
        font-size: 20px;
    }
}
