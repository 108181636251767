@import "../../../styles/var";

.study {
    margin-top: 180px;
    @media (max-width: $md6+px) {
        margin-top: 150px;
    }
}
.studyBody {
}
.studyRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
        justify-content: center;
    }
}
.studyRowLeft {
    flex: 0 1 790px;
    @media (max-width: $md1+px) {
        flex: 0 1 600px;
    }
    @media (max-width: $md3+px) {
        flex: 0 1 100%;
        margin-bottom: 30px;
    }
}
.studyRowLeftTitle {
    margin-bottom: 40px;
    @media (max-width: $md4+px) {
        margin-bottom: 30px;
    }
    @media (max-width: $md5+px) {
        margin-bottom: 15px;
    }
}
.studyRowLeftText {
    margin-bottom: 30px;
    color: #000;
    font-weight: 400;
    @media (max-width: $md5+px) {
        margin-bottom: 15px;
    }
}
.studyRowLeftSubText {
    color: #2a6056;
    font-weight: 600;
}
.studyRowRight {
    flex: 0 1 632px;
    @media (max-width: $md1+px) {
        flex: 0 1 600px;
    }
    @media (max-width: $md2+px) {
        flex: 0 1 550px;
    }
    img {
        width: 100%;
    }
}
