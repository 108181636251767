@import '../../../styles/var';

.whoWeAre {
    position: relative;
    display: flex;
    margin-top: 100px;
    @media(max-width: $md4+px) {
        display: block;
    }
    .content {
        width: 790px;
        @media(max-width: $md2+px) {
            width: 520px;
        }
        @media(max-width: $md4+px) {
            width: 100%;
        }
        .text {
            margin: 40px 0;
            color: rgba(0, 0, 0, 0.7);
            @media(max-width: $md4+px) {
                margin: 30px 0;
            }
            @media(max-width: $md6+px) {
                margin: 20px 0;
            }
        }
    }
    .images {
        position: relative;
        @media(max-width: $md4+px) {
            display: none;
        }
        img {
            position: absolute;
            transition: all 0.3s;
        }
        .coin_01 {
            z-index: 3;
            top: -100px;
            left: -80px;
            @media(max-width: $md1+px) {
                width: 600px;
                top: 0;
            }
            @media(max-width: $md2+px) {
                top: -50px;
                width: 500px;
                left: 70px;
            }
            &.active {
                transform: rotate(-10deg);
            }
        }
        .coin_02 {
            z-index: 2;
            top: 150px;
            left: 320px;
            @media(max-width: $md1+px) {
                width: 350px;
                top: 200px;
                left: 200px;
            }
            @media(max-width: $md2+px) {
                width: 260px;
                top: 150px;
                left: 270px;
            }
            &.active {
                transform: rotate(10deg);
                top: 180px;
                @media(max-width: $md1+px) {
                    top: 230px;
                }
                @media(max-width: $md2+px) {
                    top: 180px;
                }
            }
        }
    }
}